import { PreviewSecretContext } from '@dreamstack/artemis'
import {
  NotificationContextProvider,
  NotificationHub,
} from '@dreamstack/feature-components'
import { SeoDefaults } from '@dreamstack/seo'
// import { ThemeProvider } from './ThemeProvider'
import { GlobalStyles, ThemeProvider } from '@dreamstack/theme'
import { GoogleAnalyticsContextProvider } from '@dreamstack/tracking'
import type { FunctionComponent } from 'react'
import { NEXT_PUBLIC_GOOGLE_ANALYTICS_ID } from '../config/env'
import { ApolloProvider } from './ApolloProvider'

export const MainProvider: FunctionComponent<React.PropsWithChildren<{ pageProps: any }>> = ({
  children,
  pageProps,
}) => {
  return (
    <>
      <ThemeProvider>
        <SeoDefaults siteTitle="Accentro International" />
        <GlobalStyles />
        <PreviewSecretContext.Provider
          value={{ previewModeSecret: pageProps.previewModeSecret }}
        >
          <ApolloProvider pageProps={pageProps}>
            <GoogleAnalyticsContextProvider
              trackingId={NEXT_PUBLIC_GOOGLE_ANALYTICS_ID}
            >
              <NotificationContextProvider>
                <NotificationHub />
                <>{children}</>
              </NotificationContextProvider>
            </GoogleAnalyticsContextProvider>
          </ApolloProvider>
        </PreviewSecretContext.Provider>
      </ThemeProvider>
    </>
  )
}
